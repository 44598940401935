/**
 * @class Common
 *
 * Base class for page controllers in the mobile framework.
 * Is an EventEmitter.
 *
 * Emits `show` event when `show()` method is called.
 * Emits `hide` event when `hide()` method is called.
 */
"use strict";

//import $ from 'jquery';
import EventEmitter from "eventemitter2";

class CommonPage extends EventEmitter {
   /**
    * @param {string} pageID
    *      The DOM element ID of the page div
    * @param {string} [template]
    *      Optional path to the template file.
    *      Default is no template.
    * @param {string} [css]
    *      Optional path to the CSS file.
    *      Default is no CSS.
    */
   constructor(pageID, template, css) {
      super({
         wildcard: true
      });
      this.template = template;
      this.css = css;
      this.pageID = pageID;
      this.$element = $("#" + pageID);
      this.app = null;
   }

   async init(app) {
      this.app = app;
      await this.render();
   }

   /**
    * Inserts HTML and CSS into the document
    */
   async render() {
      if (this.$element.length === 0) {
         // Create the page div if it does not exist
         this.$element = $(`<div id="${this.pageID}" class="xpage">`);
         $("body").append(this.$element);
      } else if (this.$element.hasClass("xpage") == null) {
         this.$element.addClass("xpage");
      }

      this.addCSS(this.css);

      $(window).on("resize", () => {
         if (this.$element.css("display") !== "none") {}
      });

      await this.addHTML(this.template);
   }

   /**
    * Load a CSS file into the document
    */
   addCSS(cssFilePath) {
      if (cssFilePath) {
         $("<link>")
            .appendTo("head")
            .attr({
               type: "text/css",
               rel: "stylesheet",
               href: cssFilePath
            });
      }
   }

   /**
    * Add HTML from a template into the page element
    * @param {string} templateFilePath
    * @return {Promise}
    */
   async addHTML(templateFilePath) {
      await new Promise((resolve, reject) => {
         if (templateFilePath == null) {
            resolve();
            return;
         }
         $.get(templateFilePath)
            .fail((err) => {
               console.log(err);
               reject(err);
            })
            .done((html) => {
               this.$element.html(html);
               resolve();
            });
      });
   }

   /**
    * Hide this page
    */
   hide() {
      this.$element.hide();
      this.emit("hide");
   }

   /**
    * Show this page and hide all the others.
    */
   show() {
      $("body > div.xpage").hide();
      this.$element.show();
      $("body").scrollTop(0);
      this.emit("show");
   }

   /**
    * Shortcut for this.$element.find()
    */
   $(pattern) {
      if (this.$element == null) {
         throw new Error("DOM element not initialized yet");
      }
      return this.$element.find(pattern);
   }
}

export default CommonPage;
