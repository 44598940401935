/*
 * BackgroundTimeout
 * Add listeners for when you put app in background and take it out.
 * Compare the time differene to the duration you set and force relogin if greater.
 */

module.exports = {
   /*
    * enabled  {bool}  [true, false]
    *
    * Note: setting this to true, will enable background timeout
    */
   enabled: false,

   /*
    * duration  {int}
    * How many minutes before a relogin is forced?
    */
   duration: 9
};
