// index.js
// This is the initial bootstrap file for our Application
"use strict";

import app from "./platform/app";
import definitions from "./definitions.json";
import definitions_user_object from "./definitions_user_object.json";

// Applications
import financial from "./applications/financial/financial.js";

import "./customF7Helpers.js";
import "./customMissingFunctionality.js";

const config = require("./config/config.js");

const TIMEOUT = 10000;
let initTimeout = setTimeout(() => {
   let linkText = " ";
   const isAndroid = navigator.userAgent.match(/Android/);
   const isCrosswalk = navigator.userAgent.match(/Crosswalk/);

   // For Android, provide a link to download the Crosswalk version
   if (isAndroid && !isCrosswalk) {
      linkText +=
         "You may try to download this " +
         '<a href="https://sdc.appdevdesigns.net/connexted-crosswalk.apk" target="_system">substitute version</a>' +
         " instead.";
   }

   $.alert(
      "<p>" +
         "This app might not work on your device." +
         linkText +
         "</p><p>" +
         "If the problem continues, please let us know what device" +
         " and system version you are using and we will try to" +
         " solve it." +
         "</p>",
      "<t>Sorry</t>"
   );
}, TIMEOUT);

(async () => {
   await app.init(
      definitions_user_object.definitions.concat(definitions.definitions),
      [financial]
   );

   // System DeviceReady signal
   await new Promise((resolve /* , reject */) => {
      // if not in a cordova environment, then we need to
      // simulate the deviceready signal, so our pages can
      // initialize.
      if (typeof cordova === "undefined") {
         resolve();
         return;
      }

      // if we are in a cordova enviroment (on a mobile platform)
      // we can just wait for the signal:
      document.addEventListener(
         "deviceready",
         () => {
            StatusBar.styleBlackOpaque();
            resolve();
         },
         false
      );
      document.addEventListener("orientationchange", () => {});
      // stop on back
      document.addEventListener("backbutton", onBackKeyDown, false);
      //    // stop on back

      function onBackKeyDown(e) {
         if ($(".modal-in").length > 0) {
            app.popup.close();
            return false;
         } else if ($(".card-opened").length > 0) {
            app.card.close(".card-expandable");
         } else {
            app.toast
               .create({
               text: 'Do you want to exit?',
               closeButton: true,
               closeButtonText: 'Exit',
                  closeButtonColor: 'lime',
                  closeTimeout: 2000,
               on: {
                  closeButtonClick: function () {
                  navigator.app.exitApp();
                     e.preventDefault();
                  },
               }
            }).open();
         }
      }
   });
   clearTimeout(initTimeout);
   initTimeout = null;
   if (config.platform.encryptedStorage || config.platform.passwordProtected) {
      app.show("passwordPage");
      return;
   }
   app.show("appPage");
})();
