/*
 * CodePush.js
 * default config options for codepush.
 */

module.exports = {
   keys: {
      ios: "..........",
      android: "........."
   }
};
