"use strict";

import app from "./platform/app.js";

function cleanResult(res) {
   ['"', "\t"].forEach((r) => {
      res = res.replaceAll(r, "");
   });
   res = res.trim();
   return res;
}

// Template7 is required
if (typeof Template7 == "undefined") {
   throw new Error("Template7 is required to build helpers");
}

// // Applications is required...imported above
// if (typeof Applications == "undefined") {
//    throw new Error("Applications is required to build helpers");
// }

// moment is required
if (typeof moment == "undefined") {
   throw new Error("moment is required to build helpers");
}

Template7.registerHelper("cities", (cities, selected) => {
   var html = "";
   cities.forEach((city) => {
      var selectedHTML = city.uuid == selected ? "selected='selected'" : "";
      html += `<option ${selectedHTML} value="${city.uuid}">${city["City Name"]}</option>`;
   });
   return html;
});

// create a helper in template7 so we can properly display numbers with commas
Template7.registerHelper("commas", (parent, field) => {
   var number = extractNumber(parent, field);
   number = Math.round(number * 100) / 100;
   return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
});

// set number to absolute value before displaying
Template7.registerHelper("commasAbs", (parent, field = false) => {
   var number = extractNumber(parent, field);
   let absoluteNum = Math.abs(Number(number));
   absoluteNum = Math.round(absoluteNum * 100) / 100;
   if (isNaN(absoluteNum)) {
      return "0";
   }
   return absoluteNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
});

// Helper to console.log out the data for an object
// ex: {{console this 'object'}}\
/* global Template7 */
Template7.registerHelper("console", (obj, text) => {
   console.log(obj);
   return "";
});

// Helper to display data-cy attribute for testing
// "Pu Juan" becomes "Pu_Juan"
/* global Template7 */
Template7.registerHelper("dataCY", (parent, object, alternateObject) => {
   if (!parent) {
      return;
   }
   if (Array.isArray(parent)) {
      parent = parent[0];
   }

   var result = parent[object] ? parent[object] : parent[alternateObject];
   if (!result) {
      return;
   }
   result = result.replace(/ /g, "_");
   return `data-cy="${cleanResult(result)}"`;
});

// Helper to format date string into usable date Information
// default format is "YYYY-MM-DD"
// ex: {{date parent 'object'}} outputs YYYY-MM-DD of the date passed
// ex: {{date parent 'object' 'MM-DD-YYYY'}} outputs MM-DD-YYYY of the date passed
Template7.registerHelper("date", (parent, object, template) => {
   var temp = "YYYY-MM-DD";
   if (typeof template == "string") {
      temp = template;
   }
   /* global moment */
   return moment(parent[object]).format(temp);
});

// Helper to get the first letter of a name/word
// ex: {{initial parent 'object'}}
Template7.registerHelper("initial", (parent, object, alternateObject) => {
   if (!parent || (!parent[object] && !parent[alternateObject])) return "";
   var result = parent[object]
      ? parent[object].substring(0, 1)
      : parent[alternateObject].substring(0, 1);
   return cleanResult(typeof result != "undefined" ? result : "");
});

// Translate text that our Translate.js cannot
// ex: {{L 'text'}}
Template7.registerHelper("L", function (text) {
   if (window.translate) {
      return window.translate(text);
   } else if (this?.$root?.app?.resources?.translate?.t) {
      return this.$root.app.resources.translate.t(text);
   } else {
      console.error("error, translate.t not found! defaulting to 'text'");
      return text;
   }
});

// Helper to get the translated value of a field that was a select list
// ex: {{listItem 'app' 'obj' 'item' selected}}
Template7.registerHelper(
   "listItem",
   (appID, obj, item, selected, language_code) => {
      // this is a sample of how we populate list options
      var list = app.pages.appPage
         .getApplicationByID(appID)
         .listItems(obj, item, language_code || app.languageDefault());

      if (selected[item]) {
         var selectedItem = selected[item];

         var chosen = list.filter((p) => {
            return p.id == selectedItem;
         });
         if (chosen[0] && chosen[0].label) {
            return cleanResult(chosen[0].label);
         }
      } else {
         return "";
      }
   },
);

// Helper to return HTML of a list of items from a field that was a select list
// ex: {{listItems 'app' 'obj' 'item' selected '<option %selected% value="%id%">%label%</option>'}}
// %selected% will return selected='selected' if you are using this in a select input
// %label% will return the translated label of the option
// %id% will return the id/value of the option
Template7.registerHelper(
   "listItems",
   (appID, obj, item, selected, template) => {
      // this is a sample of how we populate list options

      // NOTE: Profile App doesn't have an appID to use. so I'm refactoring
      // this to not require appID

      // var list = app.pages.appPage
      //    .getApplicationByID(appID)
      //    .listItems(obj, item, app.languageDefault());

      // just scan the objects directly:
      var list = [];
      var langCode = app.languageDefault();
      const object = app.abObjs.find(
         (abObj) =>
            abObj.id === obj || abObj.name === obj || abObj.label === obj,
      );
      if (object == null) return "";
      const field = object.fields(
         (f) => f.id === item || f.columnName === item,
      )[0];
      if (field == null) return "";

      field.settings.options.forEach((o) => {
         const item = {
            id: o.id,
            name: o.text,
         };
         let label = o.text;
         const properLanguage = (o.translations || []).find((t) => {
            return t.language_code == langCode;
         });
         if (properLanguage) {
            label = properLanguage.text;
         }
         item.label = label;
         list.push(item);
      });

      // If data is selected, get text
      if (selected && selected[item]) {
         var selectedItem = selected[item];

         var chosen = list.filter((p) => {
            return p.id == selectedItem;
         })[0];
      }

      if (list.length) {
         var html = "";
         list.forEach((l) => {
            var selectedAttr = "";
            if (chosen && chosen.id == l.id) {
               selectedAttr = "selected='selected'";
            }
            html += template
               .replace(/%label%/g, l.label)
               .replace(/%name%/g, l.name)
               .replace(/%id%/g, l.id)
               .replace(/%selected%/g, selectedAttr);
         });
         return html;
      } else {
         return [];
      }
   },
);

// Helper to display object properties that have two or more word names
// ex: {{print parent 'object'}}\
/* global Template7 */
Template7.registerHelper("print", (parent, object, alternateObject) => {
   if (!parent) {
      return "";
   }
   if (Array.isArray(parent)) {
      parent = parent[0];
   }
   var result = parent[object] ? parent[object] : parent[alternateObject];
   return cleanResult(typeof result != "undefined" ? result : "");
});

/** global Template7
 * Helper to display text for UI where we need to display SOMETHING if the value is empty
 * @param {string} input
 */
Template7.registerHelper("printLabel", (input) => {
   let result = "no data";
   if (typeof input == "string" && input.length > 0) {
      // if object is not defined, assume parent is the object itself
      result = input;
   }
   return cleanResult(result);
});

// Helper to get the translated value of a field that was a select list
// ex: {{listItem 'app' 'obj' 'item' selected}}
Template7.registerHelper("translate", (appID, obj, item) => {
   var lang = app.languageDefault();

   if (!obj || !obj.translations || !obj.translations.length) return "";

   var translated = "";

   obj.translations.forEach((t) => {
      if (t.language_code == lang && typeof t[item] != "undefined") {
         translated = t[item];
      }
   });

   return translated;
});

function extractNumber(parent, field) {
   if (Array.isArray(parent)) {
      parent = parent[0];
   }
   // check all fields and return 0 if undefined
   if (!parent || (!field && typeof parent != "number")) {
      console.warn("commas helper: parent or field is undefined!");
      return "0";
   }
   // check whether field can be used as an object key
   let validField = typeof parent[field] != "undefined";
   var number = validField ? parent[field] : parent;
   if (number === undefined || number === null) {
      return "0";
   }
   return number;
}
