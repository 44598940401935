
/*
 * Prepare Missing Functionality
 */
"use strict";

//
// Add Object.assign() support for older Android versions
//
// Must be writable: true, enumerable: false, configurable: true
typeof Object.assign != "function" &&
   Object.defineProperty(Object, "assign", {
      value: function assign(target /*, varArgs*/) {
         // .length of function is 2
         // TypeError if undefined or null
         if (target == null)
            throw new TypeError("Cannot convert undefined or null to object");
         const to = Object(target);
         for (let index = 1; index < arguments.length; index++) {
            const nextSource = arguments[index];

            // Skip over if undefined or null
            if (nextSource == null) continue;
            for (const nextKey in nextSource) {
               if (
                  // Avoid bugs when hasOwnProperty is shadowed
                  !Object.prototype.hasOwnProperty.call(nextSource, nextKey)
               )
                  continue;
               to[nextKey] = nextSource[nextKey];
            }
         }
         return to;
      },
      writable: true,
      configurable: true,
   });