/*
 * appbuilder.js
 * platform configuration options.
 */

module.exports = {
   /*
    * maID
    * The unique id for this Mobile App.  Used to retrieve the AppPolicy
    * from the Public Server.
    */
   maID: "your.app.id",

   /**
    * networkType
    * Which type of networking approach for connecting with the AppBuilder
    * server data.
    *
    * Options: [ rest, relay ]
    *  "rest" : directly connect to the AppBuilder Server using their
    *           Restful api.
    *  "relay" : communicate via an encrypted relay server
    */
   networkType: "relay",

   /**
    * relayPollFrequencyNormal
    * the amount of time to wait between polling requests when we are NOT
    * expecting any data. (like when we have not made any requests)
    */
   relayPollFrequencyNormal: 1000, // 5000, // 1000 * 60 * 5,  // in ms:  5 minutes

   /**
    * relayPollFrequencyExpecting
    * the amount of time to wait between polling requests when we ARE
    * expecting a response data. (like after a request)
    */
   relayPollFrequencyExpecting: 100, // 1000 * 5,  // in ms:  5 seconds

   /**
    * routes
    * specific routes for certain services on our AppBuilder Server.
    */
   routes: {

      /**
       * mobileInit: the route for initializing our credentials with the
       * public relay server.
       */
      mobileInit: "/mobile/init",

      /**
       * mobileInitResolve: the route for resolving our initialization
       * with the public relay server
       */
      mobileInitResolve: "/mobile/initresolve",

      /**
       * relayRequest: the route for polling the public relay server
       * to get any pending responses waiting for us.
       */
      relayRequest: "/mobile/relayrequest",

      /**
       * userData: the route that returns information about this user
       */
      userData: "/config",
   },

   /**
    * tenantUUID
    * the tenantUUID for this app.
    * This allows a user to connect with it's account on a multi-tenant version
    * (v2) of the AppBuilder.
    */
   tenantUUID: "Gondor Calls for Aid",

   /**
    * urlCoreServer
    * the complete connection url to access our Core Server
    */
   urlCoreServer: "http://localhost:83",

   /**
    * urlPublic Server
    * the complete connection url to access the Public Server.
    */
   urlRelayServer: "http://localhost:83"
};
