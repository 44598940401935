/**
 * @class Common
 *
 * Is responsible for managing all the routes/data/templates for a given
 * application shown under an page.
 *
 * Is an EventEmitter.
 */
"use strict";

import EventEmitter from "eventemitter2";

class Common extends EventEmitter {
   /**
    * @param {Object} [routes]
    */
   constructor(mainRoutes = [], menuRoutes = [], options = {}) {
      super({
         wildcard: options.wildcard ?? false,
      });
      // keep track of which datacollections we are managing.
      // will try to initialize these when the App initializes (init()).
      this._routes = {
         mainRoutes: (Array.isArray(mainRoutes) && mainRoutes) || [],
         menuRoutes: (Array.isArray(menuRoutes) && menuRoutes) || [],
      };
      this.page = null;
   }

   /**
    * init()
    * An App is fully initialized once all it's datacollections are
    * loaded with data.  Then this app can be displayed.
    *
    * During the initialization process, this Application emits
    * several "status" values:
    *      "platform.init"     verifying platform data stores ready
    *      "loading"           loading datacollection data
    *      "ready"             ready for operation.
    *
    * @param {PageObject} page
    *        the live instance of the Application Page Controller that
    *        displays this application.
    * @param {Array<String>} dcIDs
    * @return {Promise}
    */
   async init(page) {
      this.page = page;
      this.status = "ready";
   }

   get routes() {
      return structuredClone(this._routes);
   }

   /**
    * dataCollection()
    * return the ABDataCollection referenced by the given key
    * @param {string} key the .name of the DataColleciton to return
    * @return {ABDataCollection}
    */
   dataCollection(key) {
      return this.page.app.abDCs.find(
         (dc) => dc.id === key || dc.name === key || dc.label == key,
      );
   }

   /**
    * listItems()
    * return an array of options for a given Object.Field
    * that is defined as a List.
    *
    * What is returned is an array of [ { id:'listItemValue', label:"display text"}]
    * that represents the valid options for the specified field.
    *
    * @param {string} objKey  either the ABObject.id or it's .name
    * @param {string} fieldKey either the ABField.id or it's .name
    * @param {string} langCode the language translation of the item to return
    * @return {array}
    */
   listItems(objKey, fieldKey, langCode = "en") {
      const results = [];
      const object = this.page.app.abObjs.find(
         (abObj) => abObj.id === objKey || abObj.name === objKey,
      );
      if (object == null) return results;
      const field = object.fields(
         (f) => f.id === fieldKey || f.columnName === fieldKey,
      )[0];
      if (field == null) return results;

      field.settings.options.forEach((o) => {
         const item = {
            id: o.id,
            name: o.text,
         };
         let label = o.text;
         const properLanguage = (o.translations || []).find((t) => {
            return t.language_code == langCode;
         });
         if (properLanguage) {
            label = properLanguage.text;
         }
         item.label = label;
         results.push(item);
      });

      return results;
   }

   /**
    * object()
    * return the ABDataCollection referenced by the given key
    * @param {string} key the .name of the DataColleciton to return
    * @return {ABDataCollection}
    */
   object(key) {
      return this.page.app.abObjs.find(
         (obj) =>
            obj.id === key ||
            // TODO (Guy): Refactor this to use only id.
            obj.name === key,
      );
   }

   /**
    * reset()
    * implements a hard reset on the App.  We reset our status to
    * uninitialized state, and the perform an init()
    * @return {Promise}
    */
   async reset(force = false) {
      // TODO
   }
}

export default Common;
